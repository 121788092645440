<template>
  <div>
    <v-container class="container--fluid">
      <v-row class="mt-0 pt-0 mb-0 pb-0">
        <v-col class="col-12">
          <v-card class="ml-5 mr-5">
            <v-card-subtitle>
              <span class="blue--text text-h6">  Sistem Bilgileri  </span>
            </v-card-subtitle>
            <v-divider />
            <v-card-text>
              <v-col class="d-flex">
                <v-col class="col-4">
                  <v-icon size="60" color="blue"> mdi-solar-panel </v-icon>

                  <span class="text-h6 text-heading-4">
                    GES KURULU GÜÇ : {{ totalNominalPlantPower.value }} (MWP)
                  </span>
                </v-col>
                <v-col class="col-4">
                  <v-icon size="60" color="blue"> mdi-sitemap </v-icon>

                  <span class="text-h6 text-heading-4">
                    Saha Sayısı : {{ totalNominalPlantPower.count }}
                  </span>
                </v-col>
                <v-col class="col-4">
                  <v-icon size="60" color="blue"> mdi-account-box </v-icon>

                  <span class="text-h6 text-heading-4">
                    AKTİF KULLANICI : {{ userCount }}
                  </span>
                </v-col>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getCompany } from "@/api/Request/company";
import { getUserList } from "@/api/Request/user";
import { getCompanyFarmList } from "@/api/Request/farm";

export default {
  data: () => ({
    companyCode: null,
    userCount: 0,
    logoModal: false,
    companyData: {},
    items: [],
    totalNominalPlantPower: {
      value: 0,
      unit: "",
    },
  }),
  watch: {},
  created() {},
  mounted() {
    this.selectedFarmFunc();
  },
  methods: {
    selectedFarmFunc() {
      const storage = window.localStorage;
      const user_company = JSON.parse(
        window.atob(storage.getItem("user_company"))
      );
      this.companyCode = user_company.companyCode;
      this.prefix = user_company.prefix;

      this.getCompany();
    },

    getImage() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require("@/views/images/emptylogo.png");
    },
    getCompany() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          status: 'Active',
        },
      };

      getCompany(params).then((res) => {
        this.companyData = res.data.data;
        this.items = [
          {
            title: " Şirket Adı",
            value: res.data.data.companyName,
          },
          { divider: true, inset: true },

          {
            title: "Oluşturma Tarih",
            value: res.data.data.createTime,
          },
          { divider: true, inset: true },
          {
            title: "Email",
            value: res.data.data.email,
          },
          { divider: true, inset: true },
          {
            title: "Telefon Numarası",
            value: res.data.data.phone,
          },
          { divider: true, inset: true },
          {
            title: "Adres",
            value: res.data.data.adress,
          },
          { divider: true, inset: true },
        ];
      });
      getCompanyFarmList(params).then((res) => {
        let totalNominalPlantPower = 0;
        let count = 0;
        Object.keys(res.data.data).forEach((i) => {
          totalNominalPlantPower += parseFloat(
            res.data.data[i].NominalPlantPower.value
          );
          count += 1;
        });
        this.totalNominalPlantPower.count = count;
        this.totalNominalPlantPower.value = totalNominalPlantPower;
        this.totalNominalPlantPower.unit = "MWP";
      });

      getUserList(params).then((res) => {
        this.userCount = res.data.data.length;
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
#map {
  height: 300px;
  width: 400px;
}
</style>
